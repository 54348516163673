import {defineStore} from 'pinia'

export const useProductDataStore = defineStore('ProductDataModule', {
  state: () => ({
    brands: [],
    categories: []
  }),
  actions: {
    async fetchAllBrands() {
      let allBrands = useNuxtApp().$config?.public.brands // TODO make brands generally available
      if (allBrands) {
        let mappedBrands = allBrands.filter(b => b.Code === 'F' || b.Code === 'D').map(({Code,  Brand, BrandDescription, id, important_message, locale, SmallLogo, BigLogo}) => ({Code,  Brand, BrandDescription, id, important_message, locale, SmallLogo, BigLogo}) )
        this.brands = mappedBrands
      }
    },
    setCategories(categories) {
      this.categories = categories
    },
    async init_store() {
      await this.fetchAllBrands()
    }
  }
})
