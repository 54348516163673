<template>
  <div
    class="buyBox"
  >
    <div>
      <div class="headline-availability">
        <h4
          class="h5"
        >
          {{ loginHeadline }}
        </h4>
      </div>

      <p class="mt--1">
        {{ loginText }}
      </p>
      <div
        v-if="!isDefaultCustomer"
        class="btn-newpdp"
      >
        <kbutton
          @click="login()"
          :theme-color="'base'"
          class="subline anchor"
        >
          {{ $tv('Login') }}
        </kbutton>
      </div>
    </div>
    <div>
      <h5 class="urgent-demand-headline">
        {{ urgentDemandHeadline }}
      </h5>
      <p class="subline">
        {{ urgentDemandText }}
      </p>
      <div class="btn-newpdp">
        <kbutton
          :theme-color="'base'"
        >
          <nuxt-link
            class="anchor"
            @click="handleClick"
          >
            {{ urgentDemandButtonText }}
          </nuxt-link>
        </kbutton>
      </div>
    </div>
  </div>
</template>

<script>
import {Button} from '@progress/kendo-vue-buttons'
import {REGION_KEYS} from 'assets/js/constants.js'
import {useGtm} from '@gtm-support/vue-gtm'

export default {
  name: 'ProductBuyBoxAnonymous',
  components: {
    kbutton: Button
  },
  props: {
    isDefaultCustomer: {
      type: Boolean,
      default() {
        return false
      }
    },
    product: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    currentRegionIsNA() {
      return this.$globalization.__currentRegion === REGION_KEYS.NA
    },
    loginHeadline() {
      return this.currentRegionIsNA
        ? this.$tv('login.headline.na', 'buyBoxAnonymous')
        : this.$tv('availabilityPricing', 'product')
    },
    loginText() {
      return this.currentRegionIsNA
        ? this.$tv('login.text.na', 'buyBoxAnonymous')
        : !this.isDefaultCustomer ? this.$tv('order-and-prices-text', 'product') : this.$tv('order-and-prices-no-customer-context-text', 'product')
    },
    urgentDemandHeadline() {
      return this.currentRegionIsNA
        ? this.$tv('urgentDemand.headline.na', 'buyBoxAnonymous')
        : this.$tv('urgent-demand', 'product')
    },
    urgentDemandText() {
      return this.currentRegionIsNA
        ? this.$tv('urgentDemand.text.na', 'buyBoxAnonymous')
        : this.$tv('urgent-demand-text', 'product')
    },
    urgentDemandButtonText() {
      return this.currentRegionIsNA
        ? this.$tv('urgentDemand.buttonText.na', 'buyBoxAnonymous')
        : this.$tv('FindDistributor')
    }
  },
  methods: {
    login() {
      navigateTo({path: '/auth/login'})
    },
    handleClick() {
      if (this.currentRegionIsNA) {
        this.$eventbus.emit('showContactPopup')
        this.trackContactUsClick()
      } else {
        navigateTo({path: '/distributor-search'})
      }
    },
    trackContactUsClick() {
      useGtm()?.trackEvent({
        event: 'partnerh_askus',
        category: 'partner_hub',
        ecommerce: {
          sku: this.product.sku
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.buyBox {
  .headline-availability {
    .h5 {
      margin: 0 0 1rem;
    }
  }

  .btn-newpdp {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  &__loggedInContent {
    margin-bottom: 5em;

    &--opacity {
      opacity: .5;
    }
  }

  .k-button {
    width: 9.1em;
  }

  .anchor {
    text-decoration: none;
    color: $color__white;
    font-weight: $base-text-font-weight-semi-bold;
  }

  .urgent-demand-headline {
    margin: 2rem 0 1rem;
  }
}
</style>
