<template>
  <div
    class="gallery"
    v-if="imageList && imageList.length > 0"
  >
    <div
      class="gallery-list"
    >
      <div
        class="gallery-panel"
        v-for="(image, i) in imageList"
        :key="i"
        @mouseover="loadImage(image, i)"
      >
        <img
          :src="image.src"
          :alt="image?.title"
          @click="openIndex(i)"
          class="clickable"
        >
      </div>
    </div>
    <div
      class="gallery-preview"
    >
      <div class="gallery-preview-image">
        <img
          v-if="imageList[0]"
          :src="imageList[0]?.src"
          @click="openIndex(currentIndex)"
          class="clickable"
          ref="previewImage"
          :alt="imageList[0].title"
        >
      </div>
    </div>
    <div class="gallery-searchIcon">
      <ActionSearch
        :title="$tv('searchIconTooltip', 'product')"
        class="svg-action-search clickable"
        @click="openIndex(currentIndex)"
      />
    </div>
    <client-only>
      <div ref="lightBoxContainer">
        <vue-easy-lightbox
          id="Vgs"
          ref="lightbox"
          :visible="visibleRef"
          :imgs="imageList"
          :index="currentIndex"
          @hide="closeLightbox()"
        />
      </div>
    </client-only>
  </div>
</template>

<script>
import {GALLERY_TAG_WHITELIST, FALLBACK_IMAGE} from '~/assets/js/constants'
import itemImageUrlMixin from '~/mixins/item_image_url'
import ActionSearch from '~/assets/icons/action_search.svg?component'
import VueEasyLightbox from 'vue-easy-lightbox'
import {ref} from 'vue'

export default defineComponent({
  mixins: [itemImageUrlMixin],
  name: 'Gallery',
  components: {
    ActionSearch,
    'vue-easy-lightbox': VueEasyLightbox
  },
  data() {
    return {
      whitelist: GALLERY_TAG_WHITELIST,
      filteredAssets: []
    }
  },
  setup() {
    const visibleRef = ref(false)
    const indexRef = ref(0)

    return {
      visibleRef,
      indexRef
    }
  },
  props: {
    productName: {
      type: String,
      default () {
        return ''
      }
    },
    product: {
      type: Object,
      default() {
        return null
      }
    }
  },
  computed: {
    imageList() {
      let result = []
      const imageUrl = this.imageUrl(this.product, true)
      if (imageUrl !== FALLBACK_IMAGE) {
        result.push({title: this.productName, src: imageUrl})
      }
      if (this.product && this.product.galleryImages) {
        result = result.concat(this.product.galleryImages.map(i => ({title: i.AlternativeText, src: i.Image.url})))
      }
      return result
    }
  },
  methods: {
    openIndex(index) {
      this.visibleRef = true
      this.currentIndex = index
    },
    closeLightbox() {
      this.visibleRef = false
    },
    loadImage(image, index) {
      if (image && typeof image !== 'undefined') {
        if (this.$refs.previewImage !== undefined) {
          this.$refs.previewImage.src = image.src
        }
        this.$refs.previewImage.alt = image?.title
        this.currentIndex = index
      }
    }
    // left() {
    //   this.current.lightbox.onPrev()
    // },
    // right() {
    //   this.$refs.lightbox.onNext()
    // }
  }
})
</script>

<style lang="scss">
.gallery {
  margin-top: $default-margin-padding-width;
  max-width: 100%;
  margin-bottom: $default-margin-padding-width;
  height: 17.75rem;
  display: flex;
  position: relative;

  &.newpdp {
    margin: 0;
    flex-direction: column;
    height: auto;

    .gallery-searchIcon {
      display: none;
    }

    .gallery-preview {
      order: 1;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
    }

    .gallery-list {
      order: 2;
      padding-top: .5rem;
      display: flex;
      flex-flow: row wrap;
    }
  }

  .gallery-searchIcon {
    width: 3.75rem;
    display: flex;
    align-items: flex-end;

    @include breakpoint-down($md) {
      width: 5.75rem;
    }

    .svg-action-search {
      position: relative;
      bottom: 4rem;
      left: 0;
      font-size: 1.5rem;
      color: $color__primary;
    }
  }

  .gallery-list {
    scrollbar-width: none;
    overflow: hidden scroll;
    margin: 0 $default-margin-padding-width;

    &::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    }
  }

  .gallery-panel {
    margin-bottom: .3rem;
    display: flex;
    place-items: center center;

    img {
      width: auto;
      height: 4.2rem;
      max-width: 100%;
    }
  }

  .gallery-preview {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: calc(100% - 10rem);

    @include breakpoint-down($md) {
      width: calc(100% - 11rem);
    }

    &-image {
      position: relative;
      display: flex;
      justify-content: flex-start;
      margin: auto;

      img {
        object-fit: contain;
        width: 100%;

        @include breakpoint-down($sm) {
          width: calc(100% - 11rem);
        }
      }

      @include breakpoint-down($sm) {
        justify-content: center;
      }
    }
  }
}

#Vgs {
  .vgs__container {
    // add swipe left swipe right
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color__white;
    max-width: unset;
  }

  button {
    color: $color__black;
  }

  .vgs__container__img {
    height: unset;
    width: unset;
    max-height: 100%;
    max-width: 100%;
  }

  .vgs__gallery__container {
    width: unset;
    transform: none !important;
  }

  @include breakpoint-up($md) {
    .vgs__container {
      top: 20vh;
    }

    .vgs__close {
      top: 20vh;
    }
  }

  @include breakpoint-down($md) {
    .vgs__close {
      background-color: $color__white;
    }
  }
}
</style>
