import axios from '~/services/axios_auth'

export const usePartnerService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL  || 'https://apim-enext-stg.azure-api.net'

  const partner_config_axios = axios.create({
    baseURL: useRuntimeConfig().public.CUSTOMERS_API || (baseurl + '/customers-api/1.0.0')
  })

  const getPartners = async function(sku: number) {
    const headers = {
      'fstRegion': useNuxtApp().$globalization.getRegion()
    }

    const res = await partner_config_axios.get(`/partnerhubs('${sku}')`, {headers}).catch(() => {
      return null
    })
    return res?.data ? res.data : []
  }

  return {
    getPartners
  }
}
