<template>
  <div
    class="price-scales"
    id="ProductPriceScales"
  >
    <h6
      v-text="headline"
    />
    <div
      v-if="rows && rows.length > 0"
      class="price-scales__table"
    >
      <div
        class="price-scales__table__column__content"
        :class="{'four-columns': showRecommendedPrice}"
      >
        <div class="price-scales__table__column label-column">
          <div v-tv:product="'qty'" />
          <div
            v-for="priceScale in rows"
            class="price-scales__table__row"
            :key="priceScale.id"
            :class="{ 'not-applicable': !priceScale.isApplicable }"
          >
            <div v-if="!priceScale.isOngoing">
              {{ priceScale?.quantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de') }}
              <span>{{ $t(`product.${keyPieces}`, priceScale?.quantity) }}</span>
            </div>
            <div
              v-else
              class="price-scales__table__row"
              v-text="`${moreThanLabelOngoing}${$t(`product.${keyPieces}`, 2)}`"
            />
          </div>
          <div
            v-if="!rows[rows.length - 1].isOngoing"
            class="price-scales__table__row"
            v-text="`${moreThanLabel}${$t(`product.${keyPieces}`, 2)}`"
          />
        </div>
        <div class="price-scales__table__column">
          <div v-tv:product="'price-per-100'" />
          <div
            v-for="priceScale in rows"
            class="price-scales__table__row"
            :key="priceScale.id"
            :class="{ 'not-applicable': !priceScale.isApplicable }"
          >
            <Price :price="priceScale.price" />
          </div>
          <div
            v-if="!rows[rows.length - 1].isOngoing"
            class="more-than-price price-scales__table__row"
            v-tv:product="'moreThanText'"
          />
        </div>
        <div
          v-if="showRecommendedPrice"
          class="price-scales__table__column"
        >
          <div v-tv:product="'rec-per-100'" />
          <div
            v-for="priceScale in rows"
            class="price-scales__table__row"
            :key="priceScale.id"
            :class="{ 'not-applicable': !priceScale.isApplicable }"
          >
            <Price
              :price="priceScale.recommendedPrice"
              :on-request-if-zero="false"
              :hide-price-request="true"
            />
          </div>
        </div>
        <div class="price-scales__table__column">
          <div v-tv:product="'price-per-1'" />
          <div
            v-for="priceScale in rows"
            class="price-scales__table__row"
            :key="priceScale.id"
            :class="{ 'not-applicable': !priceScale.isApplicable }"
          >
            <Price :price="priceScale.price/100" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="no_price_scales"
      v-show="(!rows || rows.length === 0) && !isLoading"
      v-tv:product="'noPriceScalesFound'"
    />
  </div>
</template>

<script>
import {getCurrencySymbol} from '~/assets/js/utils/price_logic'
import Price from '~/components/PriceF'
import {PRODUCT_UNITS} from '~/assets/js/constants'
import {storeToRefs} from 'pinia'
import {useCartStore} from '@/store/cart'

export default {
  components: {
    Price
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    showRecommendedPrice: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    rows: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    dynamicHeight: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    unit: {
      type: String,
      required: false,
      default() {
        return 'PCE'
      }
    }
  },
  setup() {
    const {metainformation} = storeToRefs(useCartStore())
    return {
      cartInfos: metainformation
    }
  },
  computed: {
    moreThanLabel() {
      return this.$tv({messageKey: 'product.moreThanMax', messageValues: {maxQuantity: this.rows[this.rows.length - 1].maxQuantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')}})
    },
    moreThanLabelOngoing() {
      return this.$tv({messageKey: 'product.moreThanMax', messageValues: {maxQuantity: this.rows[this.rows.length - 1].quantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')}})
    },
    headline() {
      return this.$tv({messageKey: 'product.priceScaleHeadline', messageValues: {currency: getCurrencySymbol(this.$i18n.locale.toLowerCase(), this.cartInfos.currency)}})
    },
    keyPieces() {
      if (this.unit === PRODUCT_UNITS.MTR || this.unit === PRODUCT_UNITS.M) {
        return 'piecesM'
      } else if (this.unit === PRODUCT_UNITS.PCE || this.unit === PRODUCT_UNITS.PCS || this.unit === PRODUCT_UNITS.ST) {
        return 'pieces'
      }
      return 'piecesKG'
    }
  }
}
</script>

<style lang="scss">

.price-scales {
  h6 {
    margin: 2rem 0 .5rem;
  }

  .not-applicable {
    opacity: .5;
  }

  .price-scales__table {
    display: flex;
    flex-direction: row;

    &__column__content {
      width: 100%;
      display: block;
      text-align: right;
      font-weight: map-get($base-text-subtitle-medium, font-weight );
      font-size: map-get( $base-text-subtitle-medium, font-size );

      &.four-columns {
        >* {
          width: calc(100% / 4);

          >.more-than-price {
            text-align: right;
            width: calc(100% * 3 - 1.5rem);

            @include breakpoint-down($sm) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          }
        }
      }

      >* {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 3);

        >.more-than-price {
          text-align: right;
          width: calc(100% * 2 - 1.5rem);

          @include breakpoint-down($sm) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        height: 100%;

        :not(:first-child) {
          border-bottom: .1875rem $color__page--background solid;
        }

        &.label-column {
          text-align: left;
        }

        >* {
          padding: 0 .75rem;
          text-wrap: nowrap;
          height: 2rem;
          line-height: 2rem;
          vertical-align: middle;

          &:first-child {
            font-weight: $base-text-font-weight-semi-bold;
          }

          &:nth-child(2n) {
            background-color: $color__content--background__secondary;
          }
        }
      }
    }

    .price-scales__table__row {
      font-size: map-get($base-text-body-medium, font-size );
      font-weight: map-get($base-text-body-medium, font-weight );
    }
  }

  .no_price_scales {
    font-size:map-get($base-text-label-medium, font-size);
    font-weight:map-get($base-text-label-medium, font-weight);
  }
}
</style>
