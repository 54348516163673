<template>
  <div class="partner-single">
    <div class="partner-single__img">
      <img loading="lazy"
        :src="partner.logoUrl"
        :alt="partner.supplier"
      >
    </div>
    <div class="partner-single__status">
      <div
        v-if="PARTNER_SINGLE_STATUS.Purchase === partner.state ||
          PARTNER_SINGLE_STATUS.Request === partner.state"
        class="status__purchase"
      >
        <CheckIcon /> <span v-text="statusLabel" />
      </div>
      <div
        v-else
        class="status__request"
      >
        <WarningIcon /> <span v-text="statusLabel" />
      </div>
    </div>
    <div class="partner-single__button">
      <Button
        v-if="isPurchase"
        :theme-color="'base'"
        @click="handleClickGoToPartner()"
      >
        <span v-tv:partner-hub="'goToPartner'" />
        <OpenInNewIcon />
      </Button>
      <Button
        v-else
        :theme-color="'light'"
        @click="handleClickSendRFQ()"
      >
        <span v-tv:partner-hub="'sendRFQ'" />
      </Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {PartnerHubPartner} from '@/types/partner-hub.d'
import CheckIcon from '~/assets/icons/checkmark_filled.svg?component'
import WarningIcon from '~/assets/icons/alert_warning_yellow.svg?component'
import OpenInNewIcon from '~/assets/icons/open_in_new.svg?component'
import {PARTNER_SINGLE_STATUS} from '~/assets/js/constants.js'
import {Button} from '@progress/kendo-vue-buttons'
import useI18nExtended from '@/composables/usei18nExtended'

const i18n = useI18nExtended()

const props = defineProps<{
  partner: PartnerHubPartner
}>()
const emits = defineEmits(['partnerRFQ', 'goToPartner'])

const isPurchase = computed(() => {
  return (
    PARTNER_SINGLE_STATUS.Purchase === props.partner.state ||
    PARTNER_SINGLE_STATUS.PurchaseFactoryOrder === props.partner.state
  )
})

const statusLabel = computed(() => {
  return (
    PARTNER_SINGLE_STATUS.Purchase === props.partner.state ||
    PARTNER_SINGLE_STATUS.Request === props.partner.state
  ) ? i18n.tv('productOnStock', 'partner-hub')
    : i18n.tv('factoryOrder', 'partner-hub')
})

const handleClickGoToPartner = () => {
  emits('goToPartner', props.partner)
}
const handleClickSendRFQ = () => {
  emits('partnerRFQ', props.partner)
}
</script>

<style lang="scss">
.partner-single {
  max-width: 12rem;
  width: 100%;

  &__img {
    img {
      width: 12rem;
      height: 7rem;
    }
  }

  &__status {
    padding: 1rem 0 1.25rem;
    font-size: .875rem;
    font-weight: 300;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon.icon--svg {
      margin-right: 0.5rem;
      position: relative;
      height: 1.5rem;
      width: 1.5rem;
    }

    .status__purchase {
      .icon.icon--svg {
        color: $color__pastell-green;
      }
    }
  }

  &__button {
    .k-button-solid-light {
      background-color: $color__fst__blue__010;
      color: $color__primary;

      &:hover {
        background-color: darken($color__fst__blue__010, 5%);
      }
    }

    .k-button {
      width: 12rem;
    }

    .icon.icon--svg {
      margin-left: 0.5rem;
      color: $color__white;
    }
  }
}
</style>
